import { Row } from "react-bootstrap";
import { Container } from "react-bootstrap";

const NewDataBanner = () => {
  return (
    <>
       <Container fluid>
        <Row>
          <div className="new-data-banner">
            <span> <b>&#9432;</b> New data for 2021-22 now available </span>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NewDataBanner;
