import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";

import AlertBar from "../components/AlertBar";
import Arrow from "../images/arrowBlack.svg";
import styles from "./styles/Home.module.scss"

const Home = () => {
  return (
    <Container fluid className="wrapper">
      <div className="home-container">
        <Row>
          <Col sm="12" md="6" lg="4">
            <h1 className={`${styles["nbp-header"]}`}>National Benchmarking Portal</h1>
          </Col>

          <Col sm="12" md="6" lg={{span: "7", offset: "1"}}>
            <Row className={"mb-5 mt-3"}>
              <Col className={"mb-5"}>
                <p>
                  The National Benchmarking Portal (NBP) is a secure website-based application that provides access to compare insights from the cost and activity data from public hospitals across the country.
                  <br />
                  The NBP improves hospital managers' ability to compare differences in cost, activity and efficiency at similar hospitals using the national weighted activity unit (NWAU).
                  <br />
                  Using data collected in the National Hospital Cost Data Collection, the NBP allows users to compare cost and activity data at jurisdiction, local hospital network and hospital level, as well as cost at the cost bucket and classification level, simplifying performance benchmarking and highlighting clinical variation.
                  <br /><br />
                  Years included in the NBP: 2017-18 to <b>2021-22</b>.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" as={Link} to="/periodic-insights">
                  <h6 className={`fs-5 m-0`}>Periodic insights</h6>
                  <span>
                    <img
                      src={Arrow}
                      alt="Arrow button"
                      width="24"
                      height="24"
                    /></span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Home;
