export interface Config {
    host: string,
    secure: boolean,
    port: number,
    prefix: string,
    appId: string,
  
}

export interface AppConfig {
  config: Config
}

//NWAU app dev
// export const app: AppConfig = {
//   config: {
//     host: "ihacpaqlik02.portal.ihacpa.gov.au",
//     secure: true,
//     port: 443,
//     prefix: "nbpdev",
//     appId: "93d150d5-3620-4dda-973c-2497dfbe29b0",
//     // theme: "",
//   },
// };

// //NWAU app prod
export const app: AppConfig = {
  config: {
    host: "benchmarking.ihacpa.gov.au",
    secure: true,
    port: 443,
    prefix: "",
    appId: "ae1c8f3b-51c6-46b7-90f1-66a0cbce6316",
    // theme: "",
  },
};

//HAC app dev
// export const hacApp: AppConfig = {
//   config: {
//     host: "ihacpaqlik02.portal.ihacpa.gov.au",
//     secure: true,
//     port: 443,
//     prefix: "nbpdev",
//     appId: "1e2efc32-2715-46db-a427-a98ea044a73f",
//     // theme: "",
//   }
// }

// //HAC app prod
export const hacApp: AppConfig = {
  config: {
    host: "benchmarking.ihacpa.gov.au",
    secure: true,
    port: 443,
    prefix: "",
    appId: "fbbd1553-9dc5-4b44-8bcf-8ad886b520b9",
    // theme: "",
  }
}

//AHR app dev
// export const ahrApp: AppConfig = {
//   config: {
//     host: "ihacpaqlik02.portal.ihacpa.gov.au",
//     secure: true,
//     port: 443,
//     prefix: "nbpdev",
//     appId: "77f5135a-b312-4b88-84a9-3ac5baed6f97",
//     // theme: "",
//   }
// }

// //AHR app prod
export const ahrApp: AppConfig = {
  config: {
    host: "benchmarking.ihacpa.gov.au",
    secure: true,
    port: 443,
    prefix: "",
    appId: "cb4c1a54-0fb8-470e-8fab-ca4efc24de38",
    // theme: "",
  }
}

//prod environment - old
// export const app = {
//   config: {
//     host: "benchmarking.ihacpa.gov.au",
//     secure: true,
//     port: 443,
//     prefix: "",
//     appId: "ae1c8f3b-51c6-46b7-90f1-66a0cbce6316",
//     // theme: "",
//   },
// };