import { Row, Col, Container, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

import Banner from "../components/Banner";
import { costNwauLinks } from "../config/constants";


const Glossary = () => {
  return (
    <div className="glossary">
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Cost Per NWAU</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid >
        <Row><Col><h1 className="bold mt-4">Glossary</h1></Col></Row>
        <Row >
          <Col className="wrapper" md="8">
            <p><strong>ABF: </strong>Activity based funding</p>
            <p><strong>ADRG: </strong>Adjacent Diagnosis Related Group</p>
            <p><strong>AECC: </strong>Australian Emergency Care Classification</p>
            <p><strong>AN-SNAP: </strong>Australian National Subacute and Non-Acute Patient Classification </p>
            <p><strong>AR-DRG: </strong>Australian Refined Diagnosis Related Group Classification</p>
            <p><strong>IHACPA: </strong>Independent Health and Aged Care Pricing Authority</p>
            <p><strong>LHN: </strong>Local hospital network</p>
            <p><strong>MDC: </strong>Major diagnostic category</p>
            <p><strong>NB: </strong>Newborn</p>
            <p><strong>NBP: </strong>National Benchmarking Portal</p>
            <p><strong>NEC: </strong>National efficient cost</p>
            <p><strong>NEP: </strong>National efficient price</p>
            <p><strong>NHCDC: </strong>National Hospital Cost Data Collection</p>
            <p><strong>NHRA: </strong>National Health Reform Act</p>
            <p><strong>NWAU: </strong>National weighted activity unit</p>
            <p><strong>SRG: </strong>Service related groups</p>
            <p><strong>Tier 2: </strong>Tier 2 Non-Admitted Services Classification</p>
            <p><strong>URG: </strong>Urgency related groups</p>


            <h4>Definitions</h4>
            <p><strong>Average cost per record: </strong>Total cost divided by total records for that selection.</p>
            <p><strong>Cost per NWAU: </strong>Total cost divided by total NWAU.</p>
            <p><strong>Data included in the NBP: </strong>The data presented in the NBP comprises of costed activity data; in other words, the records submitted in the activity and NHCDC datasets after the unqualified baby adjustment and removal of 'very long stay' patients.</p>
            <p><strong>MDC: </strong>A classification of admitted episodes of care, determined by the primary affected body system or cause of admission. This is a coarser classification than the AR-DRG classification.</p>
            <p><strong>NWAU: </strong>A measure of health service activity expressed as a common unit, against which the NEP is paid. It provides a way of comparing and valuing each public hospital service, whether it is an emergency department presentation, admission or outpatient episode, weighted for clinical complexity.</p>
            <p><strong>NWAU per record: </strong>Total NWAU divided by total records for that selection.</p>
            <p><strong>SRG: </strong>A classification of admitted episodes of care, based on the AR-DRG classification and aimed at grouping episodes according to service needs and access. This classification is coarser than the AR-DRG classification and was developed for use in planning services and projecting potential trends in hospital usage.</p>
            <p><strong>Total cost: </strong>Total sum of cost drivers used to deliver patient care by jurisdictions, submitted annually through the NHCDC.</p>
            <p><strong>Total NWAU: </strong>A measure of total hospital activity calculated by summing the NWAU for all patients whose treatment was eligible for ABF and who were admitted and discharged within the same financial year.</p>
            <p><strong>Total records: </strong>Total number of records admitted in the current or previous financial year and discharged in the current financial year.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Glossary;
